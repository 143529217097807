<template>
    <div>
        <div class="set-container">
            <div class="tab-box">
                <el-input placeholder="请输入收件人手机号或快递单号" v-model="searchValue" style="width: 500px" class="input-box">
                    <el-select v-model="searchMethod" slot="prepend" placeholder="请选择" @change="chooseSearchMethod" class="select-box">
                        <el-option label="按收件人手机号搜索" :value="1"></el-option>
                        <el-option label="按快递单号搜索" :value="2"></el-option>
                    </el-select>
                    <el-button slot="append" @click="searchBtn">搜索</el-button>
                </el-input>
                <div class="right-wrapper">
                    <el-button @click="inputBtn" type="primary">录入</el-button>
                    <el-button @click="pickupBtn" type="success">取件</el-button>
                    <el-button @click="exportBtn" type="warning">导出</el-button>
                </div>
            </div>
            <el-tabs v-model="status" @tab-click="handleClick" type="card">
                <el-tab-pane label="全部快件" name="-1"></el-tab-pane>
                <el-tab-pane label="待取件" name="0"></el-tab-pane>
                <el-tab-pane label="已取件" name="1"></el-tab-pane>
                <el-tab-pane label="撤销件" name="2"></el-tab-pane>
            </el-tabs>

            <el-table :data="infoList" class="customTable" style="width: 100%; flex: 1" height="1%" ref="multipleTable" row-key="id" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="70" :reserve-selection="true"></el-table-column>
                <el-table-column prop="accept_user" label="取件人" align="center"></el-table-column>
                <el-table-column prop="mobile" label="手机号" align="center"></el-table-column>
                <el-table-column prop="name" label="快递公司" align="center"></el-table-column>
                <el-table-column prop="mail_no" label="快递单号" align="center"></el-table-column>
                <el-table-column label="状态" align="center">
                    <template slot-scope="scope">
                        {{scope.row.status === 0 ? '待取件' : scope.row.status === 1 ? '已取件' : scope.row.status === 2 ? '撤销件' : '删除'}}
                    </template>
                </el-table-column>
                <el-table-column prop="courier_mobile" label="快递员电话" align="center"></el-table-column>
                <el-table-column label="操作" fixed="right" width="240">
                    <template slot-scope="scope">
                        <template v-if="scope.row.status === 0">
                            <el-link type="primary" :underline="false" @click="editBtn(scope.row)">编辑</el-link>
                            <el-link type="warning" :underline="false" @click="cancelBtn(scope.row)">撤销</el-link>
                            <el-link type="success" :underline="false" @click="sendBtn(scope.row)">发送提醒</el-link>
                        </template>
                        <el-link type="danger" :underline="false" @click="delBtn(scope.row)">删除</el-link>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="pages-center"
                           :current-page="listPages.currentPageNum"
                           :page-size="listPages.eachPageNum"
                           layout="prev, pager, next, jumper"
                           :total="listPages.total"
                           @current-change="pageCurrentChange">
            </el-pagination>

            <el-dialog title="快递录入" :visible.sync="dialogVisible" width="500px" custom-class="custom-dialog"
                       :close-on-click-modal="false" @opened="openedDialog" @close="closeDialog">
                <el-form :model="addForm" :rules="rules" ref="addForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="取件人" prop="accept_user">
                        <el-input v-model="addForm.accept_user"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="mobile">
                        <el-input v-model="addForm.mobile"></el-input>
                    </el-form-item>
                    <el-form-item label="快递单号" prop="mail_no">
                        <el-input v-model="addForm.mail_no"></el-input>
                    </el-form-item>
                    <el-form-item label="快递公司" prop="mail_company_id">
                        <el-select filterable v-model="addForm.mail_company_id" placeholder="请选择" style="width: 100%">
                            <el-option :label="item.name" :value="item.id" v-for="item in companyList" :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="柜门编号" prop="cupboard_door">
                        <el-input v-model="addForm.cupboard_door"></el-input>
                    </el-form-item>
                    <el-form-item label="快递状态" prop="status">
                        <el-select v-model="addForm.status" placeholder="请选择" style="width: 100%">
                            <el-option label="待取件" value="0"></el-option>
                            <el-option label="已取件" value="1"></el-option>
                            <el-option label="撤销件" value="2"></el-option>
<!--                            <el-option label="删除" value="3"></el-option>-->
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" style="text-align: center">
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="submitForm('addForm')" style="margin-left: 30px">保存
                    </el-button>
                </div>
            </el-dialog>

            <el-dialog title="请输入取件码" :visible.sync="dialogCode" width="500px" custom-class="custom-dialog"
                       :close-on-click-modal="false" @close="closeCodeDialog">
                <el-form :model="codeForm" :rules="codeRules" ref="codeForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="取件码" prop="code">
                        <el-input v-model="codeForm.code"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" style="text-align: center">
                    <el-button @click="dialogCode = false">取消</el-button>
                    <el-button type="primary" @click="submitCode('codeForm')" style="margin-left: 30px">保存
                    </el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import { mail_takeIndex, courier_companyIndex, mail_takeCreate, mail_takeChange, mail_takeRemind, mail_takeExport, mail_takeConfirm } from '@/config/apis.js'
    export default {
        name: "Receiving",
        data() {
            let checkContact = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请输入手机号'));
                }
                if (/^1[3456789]\d{9}$/.test(value) === false) {
                    return callback(new Error('请输入手机格式'));
                }
                return callback();
            }

            return {
                status: '-1', // tabs值
                searchValue: '',
                searchMethod: 1,
                infoList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                multipleSelection: [],

                // 弹窗
                companyList: [], // 快递公司列表
                dialogVisible: false,
                addForm: {
                    id: '',
                    mobile: '',
                    mail_company_id: '',
                    mail_no: '',
                    cupboard_door: '',
                    accept_user: '',
                    status: '0',
                },
                rules: {
                    mobile: {required: true, validator: checkContact},
                    mail_company_id: {required: true, message: '请选择快递公司', trigger: 'change'},
                    mail_no: {required: true, message: '请输入快递单号', trigger: 'blur'},
                    // cupboard_door: {required: true, message: '请输入柜门', trigger: 'blur'},
                    accept_user: {required: true, message: '请输入取件人', trigger: 'blur'},
                    // status: {required: true, message: '请选择状态', trigger: 'change'},
                },

                dialogCode: false,
                codeForm: {
                    code: '',
                },
                codeRules: {
                    code: {required: true, message: '请输入取件码', trigger: 'blur'},
                },
                btnStatus:''
            }
        },
        mounted() {
            this.getCompanyList()
            this.getList()
        },
        methods: {
            getCompanyList() {
                courier_companyIndex().then(res => {
                    this.companyList = res.data.list
                }).catch(err => {})
            },
            // 选择搜索方式
            chooseSearchMethod() {
                this.listPages.currentPageNum = 1
                this.searchValue = ''
                this.getList()
            },
            // 搜索
            searchBtn() {
                if (!this.searchValue) {
                    return this.$message.warning('请输入收件人手机号或快递单号')
                }
                this.listPages.currentPageNum = 1
                this.getList()
            },
            // 切换tab
            handleClick() {
                this.listPages.currentPageNum = 1
                this.getList()
            },
            // 获取列表
            getList() {
                let params = {
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum,
                }
                if (this.status !== '-1') {
                    params.status = this.status
                }
                if (this.searchMethod) {
                    if (this.searchMethod === 1 && this.searchValue) {
                        params.mobile = this.searchValue
                    }
                    if (this.searchMethod === 2 && this.searchValue) {
                        params.mail_no = this.searchValue
                    }
                }
                mail_takeIndex(params).then(res => {
                    this.infoList = res.data.list
                    this.listPages.total = res.data.total
                    if (this.listPages.total !== 0 && this.infoList.length === 0) {
                        this.listPages.currentPageNum--;
                        this.getList();
                    }
                }).catch(err => {})
            },
            // 切换分页
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
            // 选择表格
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            // 录入
            inputBtn() {
                this.dialogVisible = true
                this.btnStatus=0
            },
            // 取件
            pickupBtn() {
                this.dialogCode = true
            },
            // 导出
            exportBtn() {
                this.$confirm(`${this.multipleSelection.length > 0 ? '是否导出已选择的？' : '是否导出全部？'}`, '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    let params = {}
                    if (this.multipleSelection.length > 0) {
                        params.id = this.multipleSelection.map(item => item.id).join(',')
                    }
                    mail_takeExport(params).then(res => {
                        window.location.href = res.data.path
                    }).catch(err => {

                    }).finally(() => {
                        this.$refs.multipleTable.clearSelection()
                    })
                }).catch(() => {
                    this.$message.info('已取消导出');
                })
            },
            // 打开弹窗
            openedDialog() {

            },
            // 关闭弹窗
            closeDialog() {
                this.resetForm()
            },
            // 弹窗内保存
            submitForm(formName) {
                if(this.btnStatus==0){
                    this.btnStatus=1
                    this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData()
                        formData.append('mobile', this.addForm.mobile)
                        formData.append('mail_company_id', this.addForm.mail_company_id)
                        formData.append('mail_no', this.addForm.mail_no)
                        formData.append('accept_user', this.addForm.accept_user)
                        formData.append('status', this.addForm.status)
                        if (this.addForm.cupboard_door) {
                            formData.append('cupboard_door', this.addForm.cupboard_door)
                        }
                        if (this.addForm.id) {
                            formData.append('id', this.addForm.id)
                        }
                        mail_takeCreate(formData).then(res => {
                            this.resetForm()
                            this.dialogVisible = false
                            this.btnStatus=0
                            this.$message.success(res.msg)
                            this.getList()
                        }).catch(err => {})
                    } else {
                        return false;
                    }
                });
                }else{
                    this.$message.warning('请勿重复提交')
                }
                
            },
            // 重置表单
            resetForm() {
                this.$refs.addForm.resetFields();
                this.addForm = {
                    id: '',
                    mobile: '',
                    mail_company_id: '',
                    mail_no: '',
                    cupboard_door: '',
                    accept_user: '',
                    status: '0',
                }
            },
            // 编辑
            editBtn(val) {
                this.addForm = {
                    id: val.id,
                    mobile: val.mobile,
                    mail_company_id: val.mail_company_id,
                    mail_no: val.mail_no,
                    cupboard_door: val.hasOwnProperty('cupboard_door') ? val.cupboard_door: '',
                    accept_user: val.accept_user,
                    status: String(val.status),
                }
                this.dialogVisible = true
            },
            // 撤销
            cancelBtn(val) {
                this.$confirm('撤销后将无法恢复，确定要撤销？', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    let formData = new FormData()
                    formData.append('id', val.id)
                    formData.append('status', '2')
                    mail_takeChange(formData).then(res => {
                        this.$message.success(res.msg)
                        this.getList()
                    }).catch(err => {})
                }).catch(() => {
                    this.$message.info('已取消撤销');
                })
            },
            // 发送提醒
            sendBtn(val) {
                this.$confirm('是否发送提醒？', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    mail_takeRemind(val.id).then(res => {
                        this.$message.success(res.msg)
                    }).catch(err => {})
                }).catch(() => {
                    this.$message.info('已取消发送提醒');
                })
            },
            // 删除
            delBtn(val) {
                this.$confirm('删除后将无法恢复，确定要删除？', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    let formData = new FormData()
                    formData.append('id', val.id)
                    formData.append('status', '3')
                    mail_takeChange(formData).then(res => {
                        this.$message.success('删除成功')
                        this.getList()
                    }).catch(err => {})
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },

            // 取件码取件
            submitCode(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData()
                        formData.append('code', this.codeForm.code)
                        mail_takeConfirm(formData).then(res => {
                            this.$message.success(res.msg)
                            this.getList()
                            this.dialogCode = false
                        }).catch(err => {})
                    } else {
                        return false;
                    }
                });
            },
            // 重置表单
            resetCodeForm() {
                this.$refs.codeForm.resetFields();
                this.codeForm = {
                    code: '',
                }
            },
            closeCodeDialog() {
                this.resetCodeForm()
            },
        }
    }
</script>

<style scoped lang="scss">
    .set-container {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        padding: 0 20px 20px;
        box-sizing: border-box;
    }
    .tab-box {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .right-wrapper {
            margin-top: 20px;
        }
    }
    ::v-deep .input-box {
        margin-right: 20px;
        margin-top: 20px;
        .el-input-group__prepend {
            background: #fff;
        }
        .select-box {
            width: 180px;
        }
        .el-input-group__append {
            color: #fff;
            background: #409eff;
        }
    }
</style>